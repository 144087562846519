$cdnUrl: 'https://cdn.harisenin.com';
// Constants
@import "constants/variables";
@import "constants/mixins";

// Base
@import "base/base";
@import "base/typography";
@import "base/error-page";
@import "base/select";

// Vendors
@import "vendors/rc-collapse";
@import "vendors/react-carousel.es.css";
@import "vendors/video.scss";

@import "animate";
@import "alert";
@import "popover";
@import "custom";
@import "checkbox";
@import "slider";
@import "material";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800&display=swap");

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}

.invoice-list {
  &:before {
    content: counter(item);
  }
}

@layer base {
  h1 {
    font-weight: 600;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #222325;
  }

  body {
    position: relative;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    color: theme("colors.green");
  }

  dl,
  ol,
  ul {
    margin-top: 0;
    margin-bottom: 1rem;
    padding-inline-start: 20px;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  hr {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Define the transition class for the data container */
.transition-fade-in {
  animation: fade-in 0.5s ease-in;
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-10);
  }
}

/* Define the transition class for the data container */
.transition-fade-out {
  animation: fade-out 0.5s ease-out;
  opacity: 0;
}