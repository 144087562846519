$font-primary: "DM Sans";

$black: #222235;
$dark-black: #222; // digunakan
$light-black: #484848; // digunakan
$light-half-black: #4f4f4f; // digunakan
$red: #da0000; // digunakan
$new-red: #dd5260; //digunakan
$light-orange: #f2a45e;
$blue: #003bde; // digunakan
$blue-sky: #0077b5; //digunakan
$soft-blue: #0e76a8;
$dark-green: #04834e; // digunakan
$soft-green: #0dad8e;
$light-blue: #b2d7f7; // digunakan
$grey: #979797; // digunakan
$light-grey: #909090; // digunakan
$dark-grey: #696969; // digunakan
$soft-grey: #a7a7a7; // digunakan
$white: #fff; // digunakan
$green: #0dad8e; //digunakan
$yellow: #fea816; // digunakan
$light-green: #e6f6f3; // digunakan
$dark-blue: #00295f; // digunakan

$facebook: #4267b2;
$whatsapp: #4ac959;
$linkedin: #2867b2;
$twitter: #1da1f2;
$telegram: #0088cc;

$font-colors: (
  "header": #222325,
  "primary": #222325,
  "footer": #a5b0b7,
  "detail": #00010f,
);

$theme-colors: (
  "primary": $blue,
  "success": $green,
  "danger": $red,
);

$bg-colors: (
  "white": #ececec,
  "white-2": #f1f3f8,
  "light-white": #f7f7f7,
  "soft-white": #e7f7f4,
  "search": #f7f9fa,
  "green": #e6f6f3,
  "dark-green": #07401c,
  "dark-blue": #001859,
);

$radius: (
  "40px": 40px,
  "20px": 20px,
  "15px": 15px,
  "normal": 10px,
  "small": 5px,
  "big": 8px,
  "round": 50%,
  "xs": 3px,
);

$borders: (
  "normal": 1px solid rgba(151, 151, 151, 0.1),
  "thin": 1px solid #ececec,
  "form": 1px solid rgba(105, 105, 105, 0.3),
  "form-focused": 1px solid rgba(0, 0, 0, 0.3),
  "green": 1px solid rgba(13, 173, 142, 0.4),
);

$font-sizes: (
  "xs": 10px,
  "small": 12px,
  "normal": 14px,
  "medium": 16px,
);

$title-sizes: (
  "xl": 26px,
  "big": 22px,
  "medium": 20px,
  "normal": 18px,
  "small": 16px,
);

$media-query: (
  "mobile": 480px,
  "tablet": 768px,
  "medium-screen": 1024px,
  "laptop-s": 1280px,
);

$border-color: 1px solid rgba(151, 151, 151, 0.1);
$border-inbox: 2px solid rgba(151, 151, 151, 0.2);
$thin-border: 1px solid #ececec;
