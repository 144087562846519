.material_input {
  position: relative;

  input {
    width: 100%;
    border: 1px solid rgba(58, 53, 65, 0.23);
    border-radius: 0;
    padding: 16px;
    color: #222325;

    &.active, &:active, &:focus {
      border: 2px solid #0dad8e;
      outline: none;

      + .label .text {
        color: #0dad8e;
      }
    }

    &.error {
      border: 2px solid #dd5260;

      + .label .text {
        color: #dd5260;
      }
    }
  }

  .label {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 16px;
    display: flex;
    align-items: center;
    pointer-events: none;


    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  .label .text {
    transition: all 0.15s ease-out;
    color: #484848;
  }

  input:focus + .label .text,
  :not(input[value=""]) + .label .text {
    font-size: 12px;
    transform: translate(0, -150%);
    background-color: white;
    padding-left: 4px;
    padding-right: 4px;
  }
}

.material_textarea {
  textarea {
    width: 100%;
    border: 1px solid rgba(58, 53, 65, 0.23);
    border-radius: 0;
    padding: 16px;
    color: #222325;

    &:focus {
      outline: none;
    }

    &.active {
      border: 2px solid #0dad8e;
      outline: none;

      + .label .text {
        color: #0dad8e;
      }
    }

    &.error {
      border: 2px solid #dd5260;

      + .label .text {
        color: #dd5260;
      }
    }
  }

  .label {
    position: absolute;
    top: 16px;
    bottom: 0;
    left: 16px;
    height: fit-content;
  }

  .label .text {
    transition: all 0.15s ease-out;
    color: #484848;
  }

  textarea:focus + .label .text,
  textarea.filled + .label .text {
    font-size: 12px;
    transform: translate(0, -150%);
    background-color: white;
    padding-left: 4px;
    padding-right: 4px;
  }
}
