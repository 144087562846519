.tooltip-container {
  padding-top: 10px;
  z-index: 1000 !important;
}

.slider-row {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-align: center;

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.price-box {
  /* Primary 1 */

  background: #f6f7f7;
  /* up shadow */

  box-shadow: 0px -22px 40px rgba(0, 0, 0, 0.04), 0px -9.19107px 20px rgba(0, 0, 0, 0.035),
    0px -4.91399px 12px rgba(0, 0, 0, 0.03), 0px -2.75474px 8px rgba(0, 0, 0, 0.025),
    0px -1.46302px 5.32008px rgba(0, 0, 0, 0.02), 0px -0.608796px 2.21381px rgba(0, 0, 0, 0.01);
}

.radio-wrapper {
  margin-bottom: 10px;
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
  }
  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 2px solid #0dad8e;
    border-radius: 100%;
    background: transparent;
  }
  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 10px;
    height: 10px;
    background: #0dad8e;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }
  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }
  [type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
}

.react-player {
  video {
    border-radius: 10px;
  }
}

.responsive-font {
  @media (max-width: 576px) {
    font-size: 14px !important;
  }
}

.phone_input {
  & > div {
    input {
      padding-left: 8px;
      border: none;
      font-size: 14px;

      @media (max-width: 576px) {
        font-size: 12px;
      }

      &:focus {
        border: none;
        outline: none;
      }
    }
  }
}

.custom-list {
  counter-increment: item;
  margin-bottom: 10px;
  padding-left: 36px;
  font-size: 12px;

  &:before {
    content: counter(item);
    background: #0dad8e;
    border-radius: 100%;
    margin-left: -30px;
    margin-right: 10px;
    color: white;
    width: 20px;
    height: 20px;
    font-size: 10px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
}

.checkbox-wrapper {
  input {
    opacity: 0;
    display: none;

    &:checked + label:before {
      background: #0dad8e;
    }

    &:checked + label:after {
      content: "";
      position: absolute;
      left: 5px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }

    &:disabled {
      & + label:before {
        background-color: #979797;
      }
    }
  }

  label {
    position: relative;
    cursor: pointer;
    padding-left: 30px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: white;
      border: 1px solid rgba(105, 105, 105, 0.3);
      border-radius: 4px;
    }
  }

  // Box hover
  // &:hover + label:before {
  //   background: rgba($color: $green, $alpha: 0.4);
  // }

  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }
}

.benefit-animation {
  &__icon {
    padding: 8px;
    background-color: #f7f9fa;
    transition: all 0.5s ease;
    img {
      width: 36px;
      height: 36px;
    }
  }

  &:hover {
    .benefit-animation__icon {
      padding: 12px;
    }

    .benefit-animation__title {
      color: #0038de;
    }
  }

  @include mobile {
    min-width: 50px;
    &__icon {
      img {
        width: auto;
        height: 28px;
      }
    }
  }
}

.method-animation {
  &__background {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 33.85%,
      #000000 100%
    );
  }

  &__description {
    transition: all 0.3s ease;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-height: 5em;

    @media (max-width: 576px) {
      max-height: 10em;
      -webkit-line-clamp: 10;
    }
  }

  &:hover {
    .method-animation__background {
      background: rgba(0, 0, 0, 0.8);
    }

    .method-animation__description {
      -webkit-line-clamp: 10;
      overflow: hidden;
      max-height: 9em;
    }
  }
}

.arrow_ribbon {
  height: 50px;
  padding: 0 16px;
  display: flex;
  color: white;
  align-items: center;
  position: relative;

  &--green {
    background-color: #0dad8e;
    padding-left: 24px;
    width: 66%;

    &::after {
      border-left: 25px solid #0dad8e;
    }

    @media (max-width: 576px) {
      width: 93%;
    }
  }

  &--blue {
    background-color: #003bde;
    padding-left: 30px;
    width: 28%;

    &:before {
      content: "";
      width: 0;
      height: 0;
      border-width: 25px 25px;
      border-style: solid;
      border-color: #003bde #003bde #003bde transparent;
      position: absolute;
      left: -25px;
    }

    &::after {
      border-left: 25px solid #003bde;
    }

    @media (max-width: 576px) {
      width: 93%;
      padding-left: 20px;

      &::before {
        display: none;
      }
    }
  }

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    right: -25px;
  }
}

.arrow_chevron {
  position: absolute;
  top: 0;
  width: 0;
  height: 0;
  border-left: 25px solid #0dad8e;
  border-bottom: 24px solid transparent;
  border-top: 24px solid transparent;
  right: 0px;
}

.card-box {
  box-shadow: 0px 8px 16px -6px rgba(24, 39, 75, 0.08), 0px 6px 8px -6px rgba(24, 39, 75, 0.12);
}

.reviewer-box {
  box-shadow: 0px 2px 10px 0px rgba(58, 53, 65, 0.1);
}

.open-reviewer {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0.88) 100%),
    #2196f3;
}

.blur-hide {
  background: linear-gradient(180deg, #fff 40%, rgba(255, 255, 255, 0) 212%);
}

.blur-mobile {
  background: linear-gradient(0deg, #fff 67.89%, rgba(255, 255, 255, 0) 115.26%);
}

.error-background {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0.88) 100%),
    #f44336;
}

.success-background {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0.88) 100%),
    #4caf50;
}

.warning-background {
  background: var(
    --Custom-Background-Warning-Background,
    linear-gradient(0deg, rgba(255, 255, 255, 0.88) 0%, rgba(255, 255, 255, 0.88) 100%),
    #ed6c02
  );
}

.rise-background {
  background: linear-gradient(0deg, rgba(37, 211, 119, 0.1) 0%, rgba(37, 211, 119, 0.1) 100%), #fff;
}

.table-schedule {
  td {
    font-size: 14px;
    padding: 8px 0;
  }

  span {
    font-size: 14px !important;
  }

  tr {
    padding: 8px 0;
    border-top: 1px solid var(--Other-Divider-Outlined-Card, rgba(58, 53, 65, 0.12));
    border-bottom: 1px solid var(--Other-Divider-Outlined-Card, rgba(58, 53, 65, 0.12));

    td:first-child {
      width: 130px;
    }
  }
}

.table-comparison {
  width: 100%;
  border-radius: 20px;
  border: 1px solid rgba(58, 53, 65, 0.12);
  border-collapse: separate !important;
  border-spacing: 0;

  thead {
    border-bottom: 1px solid rgba(58, 53, 65, 0.12);
    border-radius: 20px;
  }

  th {
    padding: 24px 16px;
    border-bottom: 1px solid rgba(58, 53, 65, 0.12);
    font-size: 24px;
    font-weight: 700;
    &:nth-child(2) {
      border-top: 1px solid #004ce7;
      border-left: 1px solid #004ce7;
      border-right: 1px solid #004ce7;
      background-color: #daefff;
    }
  }

  td {
    padding: 0;
  }

  tbody {
    tr {
      border: none;

      &:nth-child(odd) {
        background-color: #f7f9fa;
      }

      td {
        padding: 16px;

        &:nth-child(2) {
          border-left: 1px solid #004ce7;
          border-right: 1px solid #004ce7;
          border-bottom: 1px solid rgba(58, 53, 65, 0.12);
          background-color: #daefff;
        }
      }

      &:last-child {
        td {
          &:nth-child(2) {
            border-bottom: 1px solid #004ce7;
          }
        }
      }
    }
  }
}

.table-comparison-mobile {
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(58, 53, 65, 0.12);
  border-collapse: separate !important;
  border-spacing: 0;

  thead {
    border-bottom: 1px solid rgba(58, 53, 65, 0.12);
    border-radius: 20px;

    th {
      padding: 16px;
      border-bottom: 1px solid rgba(58, 53, 65, 0.12);
      font-weight: 700;
    }
  }

  tbody {
    td {
      width: 50%;
      padding: 16px;
    }

    tr {
      border-bottom: 1px solid rgba(58, 53, 65, 0.12);

      &:nth-child(1) {
        td {
          border-bottom: 1px solid rgba(58, 53, 65, 0.12);
        }
      }

      td {
        &:nth-child(1) {
          background-color: #daefff;
        }
      }
    }
  }
}

.markup-faq {
  @media (max-width: 576px) {
    span {
      font-size: 14px !important;
    }
  }
}
