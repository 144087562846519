.checkbox {
  input {
    opacity: 0;
    display: none;

    // Box checked
    &:checked + label:before {
      background: #0dad8e;
      border: 2px solid #0dad8e;
    }

    &:checked + label:after {
      content: "";
      position: absolute;
      left: 5px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white,
        4px -8px 0 white;
      transform: rotate(45deg);
    }
  }

  label {
    position: relative;
    cursor: pointer;
    padding-left: 30px;

    // Box.
    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: white;
      border: 2px solid #484848;
    }
  }

  // Box hover
  // &:hover + label:before {
  //   background: rgba($color: $green, $alpha: 0.4);
  // }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  //   // Disabled state label.
  //   &:disabled + label {
  //     color: #b8b8b8;
  //     cursor: auto;
  //   }

  //   // Disabled box.
  //   &:disabled + label:before {
  //     box-shadow: none;
  //     background: #ddd;
  //   }

  // Checkmark. Could be replaced with an image
}
