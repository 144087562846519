.select-container {
  .select-prefix {
    &__control {
      // border: 2px solid #ececec;
      // padding: 4px;
      border-radius: map-get($map: $radius, $key: 'big');
    }

    &__control--is-focused {
      border-color: hsl(0, 0%, 80%) !important;
      box-shadow: 0 0 0 1px hsl(0, 0%, 80%);
    }

    &__indicator-separator {
      display: none;
    }

    &__dropdown-indicator {
      color: $black;
    }

    &__option {
      &:active {
        @include bg-colors('green');
        color: $black;
      }
      &--is-focused {
        @include bg-colors('green');
      }

      &--is-selected {
        background-color: $green;
      }
    }

    &__menu {
      border-radius: map-get($map: $radius, $key: 'normal');
    }
  }
}
