// Flex
@mixin justify-content($var) {
  display: flex;
  justify-content: $var;
}

@mixin align-items($var) {
  display: flex;
  align-items: $var;
}

@mixin flex-horizontal($var) {
  display: flex;
  align-items: center;
  justify-content: $var;
}

@mixin flex($hor, $ver) {
  display: flex;
  justify-content: $hor;
  align-items: $ver;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

// Size
@mixin full-width {
  height: 100%;
  width: 100%;
}

@mixin even-size($size) {
  height: $size;
  width: $size;
}

@mixin even-img($size) {
  height: $size;
  width: $size;
  object-fit: cover;
}

@mixin profile-pic($size) {
  @include even-size($size);
  border-radius: 50%;
  object-fit: cover;
}

@mixin round-object($size) {
  @include even-size($size);
  border-radius: 50%;
}

@mixin profile-picture100 {
  @include profile-pic(100%);
}

// Variable generator
@mixin get-radius($rad) {
  border-radius: map-get($map: $radius, $key: $rad);
}

@mixin get-border($var) {
  border: map-get($map: $borders, $key: $var);
}

@mixin fixed-bg-colors($var) {
  background-color: map-get($map: $bg-colors, $key: $var);
}

// Generator
@mixin create-border($color) {
  border: 1px solid $color;
}

// Media query

@mixin mobile {
  @media (max-width: map-get($media-query, "mobile")) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: map-get($media-query, tablet)) {
    @content;
  }
}

@mixin medium-screen {
  @media (max-width: map-get($media-query, medium-screen)) {
    @content;
  }
}

@mixin laptop-s {
  @media (max-width: map-get($media-query, laptop-s)) {
    @content;
  }
}

@mixin centering {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

@mixin padding-home {
  padding-top: 80px;
  padding-bottom: 80px;
}

@mixin padding-home-med {
  padding-top: 40px;
  padding-bottom: 40px;
}

@mixin radius-top-only {
  border-top-left-radius: map-get($map: $radius, $key: "normal");
  border-top-right-radius: map-get($map: $radius, $key: "normal");
}

@mixin radius-bottom-only {
  border-bottom-left-radius: map-get($map: $radius, $key: "normal");
  border-bottom-right-radius: map-get($map: $radius, $key: "normal");
}

@mixin slider-arrow {
  position: absolute;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: $white;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 15%);
  border: none;
}

@mixin ul-reset {
  list-style: none;
  padding-left: 0;
}

@mixin count-badge {
  background-color: $red;
  border-radius: 50%;
  color: $white;
  width: 22px;
  height: 22px;
  font-size: 14px;
  @include flex-center;
}

@mixin margin-center {
  margin-left: auto;
  margin-right: auto;
}

@mixin popover-container {
  background-color: $white;
  border: map-get($map: $borders, $key: "normal");
  border-radius: map-get($map: $radius, $key: "normal");
  box-shadow: -1px 2px 3px 1px #ececec7d;
}

@mixin text-input-class {
  padding: 10px 12px;
  border-radius: map-get($map: $radius, $key: "xs");
  border: map-get($map: $borders, $key: "form");
  color: #a7a7a7;

  &:focus,
  &.focus {
    border: map-get($map: $borders, $key: "form-focused");
    // box-shadow: inset -1px 2px 5px 2px rgba(185, 185, 185, 0.3);
    outline: none;
    color: #696969;
  }
}

@mixin side-overflow {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-align: center;
}

@mixin line-clamp($line) {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
}

@mixin bg-colors($color) {
  background-color: map-get($map: $bg-colors, $key: $color);
}

@mixin slider-mobile {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-align: center;
  padding-right: 0;
  margin: 0;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@mixin slider-mobile-v2 {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-align: center;

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
