.react-player {
  border-radius: 12px;

  video {
    object-fit: cover;
    border-radius: 12px;
  }
  .react-player__play-icon {
    display: none !important;
  }
}
