.animate {
  animation: bannermove 20s linear infinite;
}

@keyframes bannermove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-left {
  animation: bannermoveleft 50s linear infinite;

  @media (max-width: 576px) {
    animation: bannermoveleft 50s linear infinite;
  }
}

@keyframes bannermoveleft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-right {
  animation: bannermoveright 50s linear infinite;

  @media (max-width: 576px) {
    animation: bannermoveright 30s linear infinite;
  }
}

@keyframes bannermoveright {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}
