@import "../constants";


.error_page {
  width: 80%;
  height: 100vh;
  display: flex;
  margin: auto;

  h2 {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 20px;
  }

  a {
    color: #0dad8e;
  }

  &__left {
    margin: auto;
  }

  &__right {
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;

    img {
      margin: 0 auto 28px;
    }
  }

  @include mobile {
    flex-direction: column;
    width: fit-content;

    &_right {
      width: 80%;
    }
  }
}
