dl,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-inline-start: 20px;
}

html {
  font-family: $font-primary !important;
}

body {
  margin: 0;
  font-family: $font-primary !important;
}

#__next {
  position: initial;
  min-height: 100vh;
  background-color: #fff;
  color: map-get($font-colors, "primary");
}

.row {
  margin-left: 0;
  margin-right: 0;
}

.fixed-alert {
  top: 0;
  position: sticky;
  width: 100%;
  z-index: 9999;
}

button {
  // @include flex-center;
  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.grey-background {
  background-color: map-get($map: $bg-colors, $key: "search");
  min-height: 91vh;

  //@include tablet {
  //  min-height: 93vh;
  //}

  &.payment-success {
    @media (max-width: 576px) {
      height: 96%;
    }
  }
}

.tooltip-container {
  padding-top: 10px;
  z-index: 1000 !important;
}

.bg-light_green {
  background-color: map-get($map: $bg-colors, $key: "green");
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.with-list {
  ul {
    list-style: disc;
    margin-bottom: 0;
  }
}

// .spinner-border {
//   color: $green !important;
// }

.container-fluid {
  padding-left: 20px;
  padding-right: 20px;
}
