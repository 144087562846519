$green: #0dad8e;

@keyframes rcToolTipZoomIn {
  0% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
  100% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
}
@keyframes rcToolTipZoomOut {
  0% {
    opacity: 1;
    transform-origin: 50% 50%;
    transform: scale(1, 1);
  }
  100% {
    opacity: 0;
    transform-origin: 50% 50%;
    transform: scale(0, 0);
  }
}

.slider {
  $this: &;

  position: relative;
  height: 14px;
  padding: 5px 0;
  width: 100%;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  overflow: initial;

  &-rail {
    position: absolute;
    width: 100%;
    background-color: #fff;
    height: 4px;

    @media (max-width: 576px) {
      height: 4px !important;
    }
  }

  &-track {
    position: absolute;
    left: 0;
    height: 4px;
    background-color: $green;
    z-index: 2;

    @media (max-width: 576px) {
      height: 4px !important;
    }
  }

  &-handle {
    position: absolute;
    width: 14px;
    height: 14px;
    margin-top: -5px;
    cursor: grab;
    border-radius: 50%;
    background-color: #fff;
    touch-action: pan-x;
    z-index: 4;

    &-dragging {
      border-color: #57c5f7;
      box-shadow: 0 0 0 5px #96dbfa;
    }

    &:focus {
      outline: none;
    }

    @media (max-width: 576px) {
      margin-top: -5px !important;
    }
  }

  &-mark {
    position: absolute;
    top: 18px;
    left: 0;
    width: 100%;
    font-size: 10px;
  }

  &-tooltip {
    position: absolute;
    z-index: 22;
    display: block;
    visibility: visible;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 1.5;
    opacity: 0.9;
    left: -9999px;
    top: -9999px;

    .tooltip-zoom-appear,
    .tooltip-zoom-enter {
      opacity: 0;
    }

    .tooltip-zoom-enter,
    .tooltip-zoom-leave {
      display: block;
    }

    .tooltip-zoom-enter,
    .tooltip-zoom-appear {
      opacity: 0;
      animation-duration: 0.3s;
      animation-fill-mode: both;
      animation-timing-function: cubic-bezier(0.18, 0.89, 0.32, 1.28);
      animation-play-state: paused;
    }

    .tooltip-zoom-leave {
      animation-duration: 0.3s;
      animation-fill-mode: both;
      animation-timing-function: cubic-bezier(0.6, -0.3, 0.74, 0.05);
      animation-play-state: paused;
    }

    .tooltip-zoom-enter.tooltip-zoom-enter-active,
    .tooltip-zoom-appear.tooltip-zoom-appear-active {
      animation-name: rcToolTipZoomIn;
      animation-play-state: running;
    }
    .tooltip-zoom-leave.tooltip-zoom-leave-active {
      animation-name: rcToolTipZoomOut;
      animation-play-state: running;
    }

    &-hidden {
      display: none;
    }

    &-placement-top {
      padding: 4px 0 8px 0;
      .tooltip-arrow {
        bottom: 4px;
        margin-left: -5px;
        border-width: 5px 5px 0;
        border-top-color: #373737;
        left: 50%;
      }
    }

    &-inner {
      padding: 6px 10px;
      color: #fff;
      text-align: left;
      text-decoration: none;
      background-color: #373737;
      border-radius: 4px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.17);
      width: fit-content;
    }

    &-arrow {
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
    }
  }
}

.small-slider {
  height: 12px;
  .slider-rail {
    height: 1px;
  }

  .slider-handle {
    width: 10px;
    height: 10px;
    margin-top: -4px;
  }
}
