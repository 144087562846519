.break-space {
  white-space: break-spaces;
}

.grid-col-auto {
  grid-template-columns: auto auto;
}

.text-base-responsive {
  font-size: 14px !important;

  @media (max-width: 576px) {
    font-size: 12px !important;
  }
}

.slider-row {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-align: center;
}

.slider-mobile {
  @media (max-width: 576px) {
    overflow-x: auto;
    overflow-y: hidden;
    scroll-snap-align: center;
    padding-right: 0;
    width: 100%;
    margin-bottom: 20px;
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none; /* IE and Edge */
      background: transparent;
      width: 0;
      height: 0;
    }
  }
}

.hide-scroll-bar {
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none; /* IE and Edge */
    background: transparent;
    width: 0;
    height: 0;
  }
}

.markup-title {
  span {
    font-size: 20px !important;

    @media (max-width: 576px) {
      font-size: 16px !important;
    }
  }
  
}

.markup {
  li,
  p,
  span {
    font-size: 14px !important;
  }

  p {
    margin-bottom: 1rem;
  }

  li {
    list-style: disc;
  }

  @media (max-width: 576px) {
    li,
    p,
    span {
      font-size: 12px !important;
    }
  }
}

.markup_list {
  margin-bottom: 10px;
}

.markup_small {
  font-size: 12px !important;

  span,
  p,
  div {
    font-size: 12px !important;
  }
}

.markup_14 {
  font-size: 14px !important;

  span,
  p,
  div {
    font-size: 14px !important;
  }
}

.overflow-auto {
  overflow: auto;
}

.underline-animation {
  /* Underline styles */
  button {
    display: block;
    position: relative;
    padding: 0.2em 0;
  }

  /* Fade in */
  button::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.1em;
    background-color: #0038de;
    opacity: 0;
    transition: opacity 300ms, transform 300ms;
  }

  button:hover::after,
  button:focus::after {
    opacity: 1;
    transform: translate3d(0, 0.2em, 0);
  }

  /* Slide in */
  button {
    overflow: hidden;
  }

  button::after {
    opacity: 1;
    transform: translate3d(-100%, 0, 0);
  }

  button:hover::after,
  button:focus::after {
    transform: translate3d(0, 0, 0);
  }
}
